import React from 'react'
import Image from "../image/image";

const AboutUsLeadership = ({ data }) => (
  <section id="leadership" className="AboutUs__leadership">
    <div className="container-fluid">
      <h2 className="text-center mb-5">{data.title}</h2>
      <div className="AboutUs__leadership__row d-flex k-row">
        {data.managers.map((manager, i) =>
          <div key={i} className="AboutUs__leadership__manager flex-column col-lg-4 col-12 px-4">
            <Image className="w-100 mb-5" image_url={manager.image} alt={manager.name} />
            <div className="text-center mb-5">
              <h4>{manager.name}</h4>
              <p className="AboutUs__leadership__role mb-4">{manager.role}</p>
              <a href={manager.link} target="_blank">
                <Image className="w-100" image_url="icons/icon-linkedin-color.svg" alt="LinkedIn" />
              </a>
            </div>
            <p>{manager.summary}</p>
            <hr />
            <blockquote>"{manager.quote}"</blockquote>
          </div>
        )}
      </div>
    </div>
  </section>
)

export default AboutUsLeadership
