import React from 'react'

function handleClick (event) {
    const wrapper = event.target.previousElementSibling;
    event.target.classList.toggle("active");
    wrapper.classList.toggle("active");

    if (wrapper.style.maxHeight) {
      wrapper.style.maxHeight = null;
    } else {
      wrapper.style.maxHeight = wrapper.scrollHeight + "px";
    }
}

const AboutUsMission = ({ data }) => (
  <section id="mission" className="AboutUs__mission">
    <div className="container-fluid">
      <div className="AboutUs__mission__row d-flex border-bottom k-row">
        <div className="AboutUs__mission__title col-lg-4 col-12 px-4">
          <h2>{data.title}</h2>
        </div>
        <i className="fa fa-arrow"></i>
        <div className="AboutUs__mission__statement col-lg-8 col-12 px-4">
          <div className="AboutUs__mission__statement-text">
            <div dangerouslySetInnerHTML={{ __html: data.statement }} />
          </div>
          <div className="AboutUs__mission__statement-full"
               dangerouslySetInnerHTML={{ __html: data.content }} />
          <div className="AboutUs__mission__statement-accordion" onClick={(e) => handleClick(e)}>
            {data.accordion}
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default AboutUsMission
