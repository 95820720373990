import React from 'react'
import Image from '../image/image'

const AboutUsCulture = ({ data }) => (
  <section id="culture" className="AboutUs__culture">
    <div className="container-fluid">
      <h2 className="text-center">{data.title}</h2>
      {data.rows.map((row, i) =>
        <div key={i} className="AboutUs__culture__row d-flex k-row">
          <div className="AboutUs__culture__content col-lg-5 col-12 px-4">
            <h4 className="mb-4">{row.title}</h4>
            <p>{row.text}</p>
          </div>
          <div className="AboutUs__culture__image col-lg-7 col-12 px-4">
            <Image className="w-100" image_url={row.image} alt={row.title} />
          </div>
        </div>
      )}
    </div>
  </section>
)

export default AboutUsCulture
