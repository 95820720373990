import React from 'react'
import Image from "../image/image";

const AboutUsSustainability = ({ data }) => (
  <section id="sustainability" className="AboutUs__sustainability">
    <div className="container-fluid">
      <h2 className="text-center">{data.title}</h2>
      <div className="AboutUs__sustainability__row d-flex k-row">
        <div className="AboutUs__sustainability__image col-lg-6 col-12 px-4">
          <Image className="w-100" image_url={data.image} alt={data.title} />
        </div>
        <div className="AboutUs__sustainability__content col-lg-6 col-12 px-4">
          <h4>{data.subtitle}</h4>
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </div>
      </div>
    </div>
  </section>
)

export default AboutUsSustainability
