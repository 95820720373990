import React from 'react'
import Image from "../image/image";

const AboutUsSecurity = ({ data }) => (
  <section id="security" className="AboutUs__security">
    <div className="container-fluid">
      <h2 className="text-center">{data.title}</h2>
      <div className="AboutUs__security__row d-flex k-row">
        <div className="AboutUs__security__image col-lg-6 col-12 px-4">
          <Image className="w-100" image_url={data.privacy.image} alt={data.privacy.title} />
        </div>
        <div className="AboutUs__security__content col-lg-6 col-12 px-4">
          <h4 className="mb-4">{data.privacy.title}</h4>
          <p>{data.privacy.text}</p>
          <ul>
            {data.privacy.list.map((item, i) =>
              <li key={i}>{item}</li>
            )}
          </ul>
        </div>
      </div>
      <div className="AboutUs__security__row d-flex k-row">
        <div className="AboutUs__security__image col-lg-6 col-12 px-4">
          <Image className="w-100" image_url={data.certified.image} alt={data.certified.title} />
        </div>
        <div className="AboutUs__security__content col-lg-6 col-12 px-4">
          <h4 className="mb-4">{data.certified.title}</h4>
          <p>{data.certified.text}</p>
          <hr />
          <blockquote>{data.certified.quote}</blockquote>
        </div>
      </div>
    </div>
  </section>
)

export default AboutUsSecurity
