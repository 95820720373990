import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo/seo'
import Hero from 'components/global/hero'
import AboutUsMission from 'components/about-us/about-us-mission'
import AboutUsLeadership from 'components/about-us/about-us-leadership'
import AboutUsCulture from 'components/about-us/about-us-culture'
import AboutUsSecurity from 'components/about-us/about-us-security'
import AboutUsSustainability from 'components/about-us/about-us-sustainability'
import GetInTouch from 'components/get-in-touch/get-in-touch'
import '../components/about-us/about-us.sass'

const AboutUs = ({ data }) => {
  const d = data.aboutUsYaml;

  return (
    <Layout bodyClass="k-dark-hero-page">
      <SEO
        title="About Us | Culture, Mission , Team"
        keywords={['About Us', 'AI', 'Machine Learning', 'AutoML', 'AI Consulting', 'ML', 'Artificial Intelligence']} />
      <div className="AboutUs">
        <Hero image={d.hero} className="AboutUs__hero" alt={`Background image for ${d.title}`}>
          <div className="container-fluid">
            <h1 className="text-light">{d.title}</h1>
          </div>
        </Hero>
        <AboutUsMission data={d.mission}></AboutUsMission>
        <AboutUsLeadership data={d.leadership}></AboutUsLeadership>
        <AboutUsCulture data={d.culture}></AboutUsCulture>
        <AboutUsSecurity data={d.security}></AboutUsSecurity>
        <AboutUsSustainability data={d.sustainability}></AboutUsSustainability>
      </div>
      <GetInTouch/>
    </Layout>
  )
}

export default AboutUs

export const pageQuery = graphql`
  query {
    aboutUsYaml {
      title
      hero 
      mission {
        title
        statement
        accordion
        content
      }
      leadership {
        title
        managers {
          image
          name
          role
          link
          summary
          quote
        }
      }
      culture {
        title
        rows {
          title
          text
          image
        }
      }
      security {
        title
        privacy {
          title
          image
          text
          list
        }
        certified {
          title
          image
          text
          quote
        }
      }
      sustainability {
        title
        image
        subtitle
        content
      }
    }
  }
`